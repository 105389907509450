import React, { Component } from 'react';
import './App.css';
import img_state0_elStelth from './images/Slider_state0_elStelth_411044.jpg';
import img_state1_elCannon from './images/Slider_state1_elCannon_411044.jpg';
import img_state2_elHeavy from './images/Slider_state2_elHeavy_411044.jpg';
import img_state3_elJets from './images/Slider_state3_elJets_411044.jpg';

export default class Slider extends Component {

  // Properties used by this component:
  // visualStateIndex

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  // --- Functions for component state index 0 (1 of 4) --- 
  
  renderState0() {
    const style_state0_elStelth = {
      height: 'auto',
     };
    
    return (
      <div className="Slider">
        <div className="layoutFlow">
          <div className="state0_elStelth">
            <img style={style_state0_elStelth} src={img_state0_elStelth} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 1 (2 of 4) --- 
  
  renderState1() {
    const style_state1_elCannon = {
      height: 'auto',
     };
    
    return (
      <div className="Slider">
        <div className="layoutFlow">
          <div className="state1_elCannon">
            <img style={style_state1_elCannon} src={img_state1_elCannon} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 2 (3 of 4) --- 
  
  renderState2() {
    const style_state2_elHeavy = {
      height: 'auto',
     };
    
    return (
      <div className="Slider">
        <div className="layoutFlow">
          <div className="state2_elHeavy">
            <img style={style_state2_elHeavy} src={img_state2_elHeavy} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
  // --- Functions for component state index 3 (4 of 4) --- 
  
  renderState3() {
    const style_state3_elJets = {
      height: 'auto',
     };
    
    return (
      <div className="Slider">
        <div className="layoutFlow">
          <div className="state3_elJets">
            <img style={style_state3_elJets} src={img_state3_elJets} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
  
  render() {
    switch (parseInt((this.state && this.state.visualStateIndexOverride !== undefined) ? this.state.visualStateIndexOverride : this.props.visualStateIndex, 10)) {
      default:
      case 0:
        return this.renderState0();
      case 1:
        return this.renderState1();
      case 2:
        return this.renderState2();
      case 3:
        return this.renderState3();
    }
  }
  
}
