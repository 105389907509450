import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/Logonarrow_elImage_405376.jpg';

export default class Logonarrow extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elImage = {
      height: 'auto',
     };
    
    return (
      <div className="Logonarrow appBg">
        <div className="layoutFlow">
          <div className="elImage">
            <img style={style_elImage} src={img_elImage} alt=""  />
          </div>
        </div>
        
      </div>
    )
  }
  
}
