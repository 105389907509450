import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/WhoWeAre_elImage_1022151.jpg';

export default class WhoWeAre extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      text2: (<div>MTX Electronics</div>),
      text2_plainText: "MTX Electronics",
      text: (<div>Since 1990, MTX Electronics  has supported the aerospace and defense industries through our expertise in design and manufacturing of electromechanical assemblies, electronic assemblies, cable/wire harness assemblies, rack/panel assemblies and computer controlled test and support equipment used on both the factory floor and the flight line.  <br /><br />MTX Electronics, Inc. has designed and manufactured test equipment in support of the F-16, F-22, C-130 and C-5 programs.  Currently, MTX Electronics is providing support equipment and factory test equipment to Lockheed Martin Aeronautics and Lockheed Martin Global Training &amp; Logistics in support of the Joint Strike Fighter (F-35) program.</div>),
      text_plainText: "Since 1990, MTX Electronics  has supported the aerospace and defense industries through our expertise in design and manufacturing of electromechanical assemblies, electronic assemblies, cable/wire harness assemblies, rack/panel assemblies and computer controlled test and support equipment used on both the factory floor and the flight line.  \n\nMTX Electronics, Inc. has designed and manufactured test equipment in support of the F-16, F-22, C-130 and C-5 programs.  Currently, MTX Electronics is providing support equipment and factory test equipment to Lockheed Martin Aeronautics and Lockheed Martin Global Training & Logistics in support of the Joint Strike Fighter (F-35) program.",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="WhoWeAre appBg">
        <div className="layoutFlow">
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elText2">
            <div className="headlineFont" style={style_elText2}>
              <div>{this.state.text2}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div><div dangerouslySetInnerHTML={{__html: this.state.text_plainText.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
