import React, { Component } from 'react';
import './App.css';
import Slider from './Slider';

export default class Carousel extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    let transformStateValue_elSlider = (input) => {
      clearTimeout(this.timerId);
      this.timerId = setTimeout(() => {
         let val = this.props.appActions.dataSlots['ds_carouselIndex'];
         this.props.appActions.updateDataSlot('ds_carouselIndex', (val + 1) % 3);
      }, 4000);
      return input;
    }
    
    return (
      <div className="Carousel">
        <div className="layoutFlow">
          <div className="hasNestedComps elSlider">
            <div>
              <Slider visualStateIndex={transformStateValue_elSlider((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_carouselIndex'] : ''))} ref={(el)=> this._elSlider = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
