import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/Slider_state3_elJets_411044.jpg';

export default class ContactForm extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      name: '',
      email: '',
      phone: '',
      website: '',
      textarea: '',
      elSendconfirmation_visible: false,
      elValidator_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  textInputChanged_name = (event) => {
    this.setState({name: event.target.value});
  }
  
  textInputChanged_email = (event) => {
    this.setState({email: event.target.value});
  }
  
  textInputChanged_phone = (event) => {
    this.setState({phone: event.target.value});
  }
  
  textInputChanged_website = (event) => {
    this.setState({website: event.target.value});
  }
  
  textAreaChanged_textarea = (event) => {
    this.setState({textarea: event.target.value});
  }
  
  onClick_elButton = (ev) => {
    // Perform action 'Validate' on element 'validator'
    const items = [
      { result: this.state.name != null && this.state.name.length > 0 && true /* validate this.state.name */, name: 'name', value: this.state.name },
      { result: this.state.email != null && this.state.email.length > 0 && /^(\S+@\S+\.\S+)?$/.test(this.state.email) /* email */, name: 'email', value: this.state.email },
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= item.result;
      if ( !valid) {
        break;
      }
    };
    this.setState({elValidator_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    this.sendData_button_to_contacts();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      label: (<div></div>),
      label_plainText: "",
      name: "",
      email: "",
      phone: "",
      website: "",
      textarea: "",
      sendconfirmation: (<div></div>),
      sendconfirmation_plainText: "",
      validator: (<div></div>),
      validator_plainText: "",
    });
  
    this.setState({elSendconfirmation_visible: !this.state.elSendconfirmation_visible});
  
  }
  
  
  sendData_button_to_contacts = () => {
    const dataSheet = this.props.appActions.getDataSheet('contacts');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      phone: this.state.phone,
      website: this.state.website,
      forward: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_forwardContactsTo'] : ''),
      message: this.state.textarea,
      email: this.state.email,
      subject: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_contactSubjectLine'] : ''),
      name: this.state.name,
    };
  
    const transformData = (input) => {
        // This function modifies the value saved to the data sheet.
      // There is a variable named 'input' that provides the input values:
      // it's a JavaScript object with properties that contain all the
      // values collected from the UI.
      input.time = new Date().toLocaleString()
      return input;
    }
    row = transformData(row);
  
    if (this.props.dataSheetId === dataSheet.id) {
      this.props.appActions.updateInDataSheet('contacts', row);
    } else {
      this.props.appActions.addToDataSheet('contacts', row);
    }
  }
  
  
  render() {
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elLabel = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#ff2600',
      pointerEvents: 'auto',
     };
    
    const style_elEmail = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#ff2600',
      pointerEvents: 'auto',
     };
    
    const style_elPhone = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };
    
    const style_elWebsite = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };
    
    const style_elTextarea = {
      display: 'block',
      backgroundColor: 'white',
      borderColor: 'lightGray',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      textTransform: 'uppercase',
      color: 'black',
      textAlign: 'center',
      backgroundColor: '#feffff',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elSendconfirmation = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'center',
     };
    const elSendconfirmation = this.state.elSendconfirmation_visible ? (
      <div className="elSendconfirmation">
        <div className="baseFont" style={style_elSendconfirmation}>
          <div>{this.props.locStrings.contactform_text_422342}</div>
        </div>
      </div>
      
     ) : null;
    const style_elValidator = {
      color: '#ff2600',
      textAlign: 'center',
     };
    const elValidator = this.state.elValidator_visible ? (
      <div className="elValidator">
        <div className="baseFont" style={style_elValidator}>
          <div>{this.props.locStrings.contactform_validator_285927}</div>
        </div>
      </div>
      
     ) : null;
    
    return (
      <div className="ContactForm">
        <div className="layoutFlow">
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elLabel">
            <div className="baseFont" style={style_elLabel}>
              <div><div dangerouslySetInnerHTML={{__html: this.props.locStrings.comp1_label_640871.replace(/\n/g, '<br>')}}></div></div>
            </div>
          </div>
          
          <div className="elName">
            <input className="baseFont" style={style_elName} type="text" placeholder={this.props.locStrings.comp1_name_453680} onChange={this.textInputChanged_name} value={this.state.name}  />
          </div>
          
          <div className="elEmail">
            <input className="baseFont" style={style_elEmail} type="email" placeholder={this.props.locStrings.comp1_email_72629} onChange={this.textInputChanged_email} value={this.state.email}  />
          </div>
          
          <div className="elPhone">
            <input className="baseFont" style={style_elPhone} type="tel" placeholder={this.props.locStrings.comp1_phone_745135} onChange={this.textInputChanged_phone} value={this.state.phone}  />
          </div>
          
          <div className="elWebsite">
            <input className="baseFont" style={style_elWebsite} type="text" placeholder={this.props.locStrings.comp1_website_767974} onChange={this.textInputChanged_website} value={this.state.website}  />
          </div>
          
          <div className="elTextarea">
            <textarea className="baseFont" style={style_elTextarea}  placeholder={this.props.locStrings.comp1_textarea_1010967} onChange={this.textAreaChanged_textarea} value={this.state.textarea}  />
          </div>
          
          <div className="elButton">
            <button className="actionFont" style={style_elButton} onClick={this.onClick_elButton} >
              {this.props.locStrings.comp1_button_238971}
            </button>
          </div>
          
          { elSendconfirmation }
          { elValidator }
        </div>
        
      </div>
    )
  }
  
}
