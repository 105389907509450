import React, { Component } from 'react';
import './App.css';
import Logowide from './Logowide';
import Logonarrow from './Logonarrow';
import Gallery from './Gallery';
import btn_icon_985647 from './images/btn_icon_985647.png';
import DropMenu from './DropMenu';

export default class GalleryScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elDropMenu_visible: false,
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elIconButton = (ev) => {
    this.setState({elDropMenu_visible: !this.state.elDropMenu_visible});
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    let contentElement_elSwapper;  // This element's content can vary based on screen size
    contentElement_elSwapper = (<Logowide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    if (this.props.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapper = (<Logonarrow appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapper = (<Logowide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapper = (<Logowide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapper = (<Logowide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    
    const style_elGallery = {
      pointerEvents: 'auto',
     };
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_985647+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elDropMenu = this.state.elDropMenu_visible ? (
      <div className="hasNestedComps elDropMenu">
        <DropMenu ref={(el)=> this._elDropMenu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen GalleryScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elSwapper">
            <div>
              {contentElement_elSwapper}
            </div>
          </div>
          
          <div className="elGallery">
            <div style={style_elGallery}>
              <Gallery ref={(el)=> this._elGallery = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
            { elDropMenu }
          </div>
        </div>
      </div>
    )
  }
  
}
