import React, { Component } from 'react';
import './App.css';
import Logowide from './Logowide';
import Logonarrow from './Logonarrow';
import WhoWeAre from './WhoWeAre';
import WhoUseUs from './WhoUseUs';
import Why3 from './Why3';
import ContactForm from './ContactForm';
import Footer1 from './Footer1';
import btn_icon_659377 from './images/btn_icon_659377.png';
import DropMenu from './DropMenu';

export default class HomeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      elDropMenu_visible: false,
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['contacts'];
      let serviceOptions = this.props.appActions.serviceOptions_contacts;
      if ( !this.props.appActions.dataSheetLoaded['contacts']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/mtx/contact-form/records", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase1(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['contacts'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elIconButton = (ev) => {
    this.setState({elDropMenu_visible: !this.state.elDropMenu_visible});
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    
    let contentElement_elSwapper;  // This element's content can vary based on screen size
    contentElement_elSwapper = (<Logowide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    if (this.props.deviceInfo.screenFormatId === 'narrow-phone') {
      contentElement_elSwapper = (<Logonarrow appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-phone') {
      contentElement_elSwapper = (<Logonarrow appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'narrow-tablet') {
      contentElement_elSwapper = (<Logowide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    else if (this.props.deviceInfo.screenFormatId === 'wide-tablet') {
      contentElement_elSwapper = (<Logowide appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />);
    }
    
    const style_elIconButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_659377+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const elDropMenu = this.state.elDropMenu_visible ? (
      <div className="hasNestedComps elDropMenu">
        <DropMenu ref={(el)=> this._elDropMenu = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
      </div>
     ) : null;
    
    return (
      <div className="AppScreen HomeScreen" style={baseStyle}>
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="hasNestedComps elSwapper">
            <div>
              {contentElement_elSwapper}
            </div>
          </div>
          
          <div className="hasNestedComps elColumns">
            <div>
              <div className="col0">
                <WhoWeAre appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
              <div className="col1">
                <WhoUseUs appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
            </div>
          </div>
          
          <div className="hasNestedComps elColumns2">
            <div>
              <div className="col0">
                <Why3 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
              <div className="col1">
                <ContactForm appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
              </div>
            </div>
          </div>
          
          <div className="hasNestedComps elFooter1">
            <div>
              <Footer1 ref={(el)=> this._elFooter1 = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elIconButton" style={style_elIconButton} onClick={this.onClick_elIconButton}  />
            { elDropMenu }
          </div>
        </div>
      </div>
    )
  }
  
}
