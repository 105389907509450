import React, { Component } from 'react';
import './App.css';
import Carousel from './Carousel';
import img_elLogo from './images/Logonarrow_elImage_405376.jpg';

export default class Logowide extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elLogo = {
      backgroundImage: 'url('+img_elLogo+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    return (
      <div className="Logowide appBg">
        <div className="layoutFlow">
          <div className="hasNestedComps elComp">
            <div>
              <Carousel ref={(el)=> this._elComp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="foreground">
          <div className="elLogo" style={style_elLogo} />
        </div>
      </div>
    )
  }
  
}
