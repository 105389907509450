import React, { Component, Fragment, useState } from 'react';



// {-Gallery}
export default class Gallery  extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
    };
    // sync with Main.js
    
  }

  
  componentDidMount() {

  }
  

  
  render() {

    return (
      <div className="Gallery" >
        <div className="GalleryElement appBg">
          <div className="layoutFlow" >
            {
            [1,2,3,4,5,6,7,8,9,10,11,12,13,13,15,16,17,18,19].map((i)=>{
              var path = "/gallery/t"+i+".jpg"
              return <img key={'img-'+i} src={path} />
            })
            }
          </div>
        </div>      
      </div> 
    )
  }
  
}


