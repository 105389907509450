import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/Why3_elImage_328550.jpg';

export default class Why3 extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      text2: (<div>Why we are different</div>),
      text2_plainText: "Why we are different",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  render() {
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    const style_elText2 = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
     };
    
    return (
      <div className="Why3 appBg">
        <div className="layoutFlow">
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elText2">
            <div className="headlineFont" style={style_elText2}>
              <div>{this.state.text2}</div>
            </div>
          </div>
          
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{this.props.locStrings.why3_text_9946}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
