import React, { Component } from 'react';
import './App.css';

export default class DropMenu extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  componentWillReceiveProps(nextProps) {
  }

  onClick_elHome = (ev) => {
    let newVal = "";
    this.props.appActions.updateDataSlot('ds_page', newVal);
  
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elClients = (ev) => {
    let newVal = "clients";
    this.props.appActions.updateDataSlot('ds_page', newVal);
  
    // Go to screen 'Clients'
    this.props.appActions.goToScreen('clients', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elGallery = (ev) => {
    let newVal = "gallery";
    this.props.appActions.updateDataSlot('ds_page', newVal);
  
    // Go to screen 'Gallery'
    this.props.appActions.goToScreen('gallery', { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    const style_elHome = {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 14.2,
      color: 'black',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elClients = {
      display: 'block',
      textTransform: 'uppercase',
      color: 'black',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elGallery = {
      display: 'block',
      textTransform: 'uppercase',
      color: 'black',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="DropMenu appBg">
        <div className="layoutFlow">
          <div className="elHome">
            <button className="font-SFUITextRegular" style={style_elHome} onClick={this.onClick_elHome} >
              Home	
            </button>
          </div>
          
          <div className="elClients">
            <button className="actionFont" style={style_elClients} onClick={this.onClick_elClients} >
              Clients
            </button>
          </div>
          
          <div className="elGallery">
            <button className="actionFont" style={style_elGallery} onClick={this.onClick_elGallery} >
              Gallery
            </button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
